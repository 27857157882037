.webpage__blog-container_article{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);

}
.webpage__blog-container_article-image{
    width: 100%;
    height: 100%;
    background-color: var(--color-footer);

}
.webpage__blog-container_article-image img{
    width: 100%;
    height: 100%;
}
.webpage__blog-container_article-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    height: 100%;
}
.webpage__blog-container_article-content p{
 font-size:11.649px;
 font-family:var(--font-family) ;
 color: white;
 font-weight: bold;
 line-height: 35px;
}
.webpage__blog-container_article-content h3{
 font-family: var(--font-family);
 font-size: 25;
 line-height: 30px;
 color: white;
 font-weight: 800;
 margin-bottom: 5rem;
 cursor: pointer;
}
.webpage__blog-container_article-content p:last-child{
  cursor: pointer;

}
@media screen and (max-width :550px) {
    .webpage__blog-container_article-content h3{
        font-size: 18px;
        line-height: 25px;
    }}