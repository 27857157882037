@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.webpage__blog{
   display: flex;
   flex-direction: column; 
}
.webpage__blog-heading{
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
}
.webpage__blog-heading h1{
    font-family: var(--font-family);
    font-size: 62px;
    font-weight: 800;
    line-height: 75px;
    letter-spacing: -0.04em;

}
.webpage__blog-container{
    display: flex;
    flex-direction: row;

}
.webpage__blog-container_groupA{
    flex: 0.75 1;
    margin-right: 2rem;
}
.webpage__blog-container_groupB{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}
@media screen and (max-width: 990px){
    .webpage__blog-container{

        flex-direction: column-reverse;
    }
    .webpage__blog-container_groupA{
        margin: 2rem 0;
    }
    .webpage__blog-container_groupA .webpage__blog-container_article{
        width: 48%;
    }
    .webpage__blog-container_groupA .webpage__blog-container_article-image{
        height: 250px;
    }
}
@media screen and (max-width: 700px){
    .webpage__blog-container_groupB{
        grid-template-columns: repeat(1, 1fr);
    }
    .webpage__blog-container_groupA .webpage__blog-container_article {
        width: 100%;
    }
    .webpage__blog-heading h1{
        font-size: 46px;
        line-height: 52px;
    }
}
@media screen and (max-width:550px) {
    .webpage__blog-heading h1{
        font-size: 34px;
        line-height: 42px;
    }
    
}
.webpage__blog-container_article{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);

}
.webpage__blog-container_article-image{
    width: 100%;
    height: 100%;
    background-color: var(--color-footer);

}
.webpage__blog-container_article-image img{
    width: 100%;
    height: 100%;
}
.webpage__blog-container_article-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    height: 100%;
}
.webpage__blog-container_article-content p{
 font-size:11.649px;
 font-family:var(--font-family) ;
 color: white;
 font-weight: bold;
 line-height: 35px;
}
.webpage__blog-container_article-content h3{
 font-family: var(--font-family);
 font-size: 25;
 line-height: 30px;
 color: white;
 font-weight: 800;
 margin-bottom: 5rem;
 cursor: pointer;
}
.webpage__blog-container_article-content p:last-child{
  cursor: pointer;

}
@media screen and (max-width :550px) {
    .webpage__blog-container_article-content h3{
        font-size: 18px;
        line-height: 25px;
    }}
.webpage__brands {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.webpage__brands div{
    flex:1 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.webpage__cta{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar);
}
.webpage__cta-content p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: #0E0E0E;
}
.webpage__cta-content h3{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 24px;
    line-height: 45px;
    color: #000000;
}
.webpage__cta-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
}
.webpage__cta-btn button{
    background: #000000;
    color: white;
    border-radius: 40px;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}
@media screen and (max-width: 650px) {
    .webpage__cta {
        flex-direction: column;
    }

    .webpage__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .webpage__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .webpage__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .webpage__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}
.webpage__features-container__feature{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    margin: 1rem;
}
.webpage__features-container__feature-title{
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}
.webpage__features-container__feature-title h1{
    font-size: 18px;
    font-weight:800 ;
    line-height: 24px;
    font-family: var(--font-family);
    letter-spacing: -0.04em;
    color: white;
}
.webpage__features-container__feature-title div{
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 1 4px 4px rgba(0,0,0,0.25);
    margin-bottom: 0.25rem;
}
.webpage__features-container__feature-text{
    flex: 2 1;
    max-width: 390px;
    display: flex;
}
.webpage__features-container__feature-text p{
    font-weight: 500;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);

}
@media screen and (max-width: 550px) {
    .webpage__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .webpage__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .webpage__features-container__feature {
        margin: 1rem 0;
    }
}
.webpage__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem;
}

.webpage__navbar-links {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.webpage__navbar-links_logo {
  margin-right: 2rem;
}

.webpage__navbar-links_logo img {
  width: 62px;
  height: 16px;
}
.webpage__navbar-links_container {
  display: flex;
  flex-direction: row;
}
.webpage__navbar-sign {
  display: flex;
  justify-content: center;
  align-items: center;
}
.webpage__navbar-links_container p,
.webpage__navbar-sign p,
.webpage__navbar-menu_container p {
  color: white;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.webpage__navbar-sign button,
.webpage__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: white;
  font-family: var(--font-family);
  font-weight: 500;
  border-radius: 5px;
  line-height: 25px;
  font-size: 18px;
  background: #ff4820;
  border: 0;
  outline: none;
  cursor: pointer;
}
.webpage__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}
.webpage__navbar-menu svg {
  cursor: pointer;
}
.webpage__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: end;
  flex-direction: column;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}
.webpage__navbar-menu_container p{
  margin: 1rem 0;
}
.webpage__navbar-menu_container-links-sign{
  display: none;
}
@media screen and (max-width: 1050px) {
  .webpage__navbar-links_container {
    display: none;
  }

  .webpage__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .webpage__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .webpage__navbar {
    padding: 2rem;
  }

  .webpage__navbar-sign {
    display: none;
  }

  .webpage__navbar-menu_container {
    top: 20px;
  }

  .webpage__navbar-menu_container-links-sign {
    display: block;
  }
}

.webpage__features{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.webpage__features-heading{
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin-right: 5rem;
}
.webpage__features-heading h1{
    font-family: var(--font-family);
    font-size: 34px;
    font-weight: 800;
    line-height: 45px;
}
.webpage__features-heading p{
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: #FF8A71;
    margin-top: 2rem;
}
.webpage__features-container{
    flex: 1.5 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 990px) {
    .webpage__features {
        flex-direction: column;
    }

    .webpage__features-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .webpage__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}
.webpage__footer{
    display: flex;
    /* border: 1px solid white; */
    /* color: white; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
}
.webpage__footer-heading{
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}
.webpage__footer-heading h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04em;
}
.webpage__footer-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid white;
    text-align: center;
    margin-bottom: 10rem;
    cursor: pointer;
}
.webpage__footer-btn p{
    box-sizing: border-box;
    font-family: var(--font-family);
    word-spacing: 2px;
    color: white;
    font-size: 18px;
    line-height: 21px;



}
.webpage__footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
}
.webpage__footer-links div{
    width: 250px;
    margin: 1rem;
}
.webpage__footer-links_logo{
    display: flex;
    flex-direction: column;
}
.webpage__footer-links_logo img{
    width: 118px;
    height: 30px;
    margin-bottom: 1rem;
}
.webpage__footer-links_logo p{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: white;
}
.webpage__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.webpage__footer-links_div h4{
    font-size: 14px;
    line-height: 16px;
    color: white;
    font-family: var(--font-family);
    margin-bottom: 1rem;
}
.webpage__footer-links_div p{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: white;
    margin: 0.5rem 0;
    cursor: pointer;

}
.webpage__footer-copyright{
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}
.webpage__footer-copyright p{
    font-size:12px ;
    line-height: 14px;
    color: white;
    font-family: var(--font-family);
}

@media screen and (max-width: 850px) {
    .webpage__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .webpage__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .webpage__footer-links div {
        margin: 1rem 0;
    }

    .webpage__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .webpage__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}
.webpage__header {
  display: flex;
}

.webpage__header-content {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 5rem;
}
.webpage__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}
.webpage__header-content p {
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 27px;
  font-weight: normal;
  color: var(--color-text);
  margin-top: 1.5rem;
}
.webpage__header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
}
.webpage__header-content__input input {
  flex: 2 1;
  width: 100%;
  min-height: 50px;
  font-size: 20px;
  line-height: 27px;
  background: var(--color-footer);
  font-family: var(--font-family);
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: white;
  border-radius: 5px 0px 0px 5px;
}
.webpage__header-content__input button {
  flex: 0.6 1;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  border-radius: 0px 5px 5px 0px;
  color: white;
  cursor: pointer;
  outline: none;
  background-color: #ff4820;
  padding: 0 1rem;
  border: none;
}
.webpage__header-content__people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}
.webpage__header-content__people img {
  width: 182;
  height: 38px;
}
.webpage__header-content__people p {
  margin: 0 0 0 0.5rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: white;
  text-align: center;
}
.webpage__header-image {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.webpage__header-image img {
  width: 100%;
  height: 100%;
}


@media screen and (max-width: 1050px) {
  .webpage__header {
    flex-direction: column;
  }
  .webpage__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .webpage__header h1 {
    font-size: 48px;
    line-height: 60px;
  }
  .webpage__header h1 {
    font-size: 16px;
    line-height: 24px;
  }
  .webpage__header-content__people {
    flex-direction: column;
  }
  .webpage__header-content__people p {
    margin: 0;
  }
  .webpage__header-content__input input,
  .webpage__header-content__input button {
    font-size: 16px;    
    line-height: 24px;
  }
} 
@media screen and (max-width: 490px) {
  .webpage__header h1 {
    font-size: 36px;
    line-height: 48px;
  }
  .webpage__header p {
    font-size: 14px;
    line-height: 24px;
  }
  .webpage__header-content__input input,
  .webpage__header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}  

.webpage__possibility{
    display: flex;
    flex-direction: row;

}
.webpage__possibility-image{
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;
}
.webpage__possibility-image img{
    width: 100%;
    height: 100%;

}

.webpage__possibility-content{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}
.webpage__possibility-content h4{
 font-size:16px ;
 line-height:30px ;
 font-weight:500;
 color:#71E5FF;
 font-family: var(--font-family);
}
  .webpage__possibility-content h4:last-child{
    color: #FF8A71;
}
.webpage__possibility-content h1{
font-family: var(--font-family);
font-size: 34px;
line-height: 45px;
font-weight: 800;
margin: 1rem 0;
}
.webpage__possibility-content p{
   font-family: var(--font-family);
   font-size: 16px;
   line-height: 30px;
   color:var(--color-text) ;
   margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .webpage__possibility {
        flex-direction: column;
    }

    .webpage__possibility-image {
        margin: 1rem 0;
    }

    .webpage__possibility-image img {
        width: unset;
        height: unset;
    }

    .webpage__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .webpage__possibility-image img {
        width: 100%;
        height: 100%;
    }
}
.webpage__whatweb {
  display: flex;
  flex-direction: column;
/* border: 1px solid white; */
  padding: 2rem;
  background: var(--color-footer);

  /* ff 3.6+ */

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */

  /* ie 10+ */

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.webpage__whatweb-feature {
  display: flex;
  /* border:1px solid white; */
}
.webpage__whatweb-feature .webpage__feature-container__feature {
  margin: 0;
}
.webpage__whatweb-feature .webpage__feature-container__feature-text {
  max-width: 700px;
}
.webpage__whatweb-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 4rem 0 2rem;
}
.webpage__whatweb-heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  max-width: 510px;
  font-family: var(--font-family);
}
.webpage__whatweb-heading p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  font-family: var(--font-family);
  color: var(--color-subtext);
  cursor: pointer;
}
.webpage__whatweb-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 2rem;
}
.webpage__whatweb-container .webpage__features-container__feature {
  flex: 1 1;
  margin: 1rem;
  min-width: 210px;
  display: flex;
  flex-direction: column;
}
.webpage__whatweb-container .webpage__features-container_feature-text{
  margin-top: 0.5rem;
}
@media screen and (max-width: 850px) {
  .webpage__whatweb-heading {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0rem;
  }

  .web__whatweb-heading p {
      margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  /* Customizing feature component as per needs */
  .webpage__whatweb-feature .webpage__features-container__feature {
      flex-direction: column;
  }

  /* Customizing feature component as per needs */
  .webpage__whatweb-feature .webpage__features-container__feature-text {
      margin-top: 0.5rem;
  }

  .webpage__whatweb-heading h1 {
      font-size: 28px;
      line-height: 40px;
  }
}

@media screen and (max-width: 450px) {
  /* Customizing feature component as per needs */
  .webpage__whatweb-container .webpage__features-container__feature {
      margin: 1rem 0;
      min-width: 100%;
  }
  .webpage__whatweb{
    padding: 2rem;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-bg);
}
a {
  color: unset;
  text-decoration: none;
}
.gradient__bg {

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */

  /* ie 10+ */

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 25%
  );
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 3rem;
}

.section__margin {
  margin: 4rem 3rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-17 17:33:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding: {
    padding: 4rem;
  }
  .section__margin: {
    margin: 4rem;
  }
}
@media screen and (max-width: 550px) {
  .section__padding: {
    padding: 2rem 2rem;
  }
  .section__margin: {
    margin: 2rem 2rem;
  }
}
@media screen and (max-width: 450px) {
  .section__margin: {
    margin: 4rem 3rem;
  }
}

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}
