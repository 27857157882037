.webpage__possibility{
    display: flex;
    flex-direction: row;

}
.webpage__possibility-image{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;
}
.webpage__possibility-image img{
    width: 100%;
    height: 100%;

}

.webpage__possibility-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}
.webpage__possibility-content h4{
 font-size:16px ;
 line-height:30px ;
 font-weight:500;
 color:#71E5FF;
 font-family: var(--font-family);
}
  .webpage__possibility-content h4:last-child{
    color: #FF8A71;
}
.webpage__possibility-content h1{
font-family: var(--font-family);
font-size: 34px;
line-height: 45px;
font-weight: 800;
margin: 1rem 0;
}
.webpage__possibility-content p{
   font-family: var(--font-family);
   font-size: 16px;
   line-height: 30px;
   color:var(--color-text) ;
   margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .webpage__possibility {
        flex-direction: column;
    }

    .webpage__possibility-image {
        margin: 1rem 0;
    }

    .webpage__possibility-image img {
        width: unset;
        height: unset;
    }

    .webpage__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .webpage__possibility-image img {
        width: 100%;
        height: 100%;
    }
}