.webpage__whatweb {
  display: flex;
  flex-direction: column;
/* border: 1px solid white; */
  padding: 2rem;
  background: var(--color-footer);

  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.webpage__whatweb-feature {
  display: flex;
  /* border:1px solid white; */
}
.webpage__whatweb-feature .webpage__feature-container__feature {
  margin: 0;
}
.webpage__whatweb-feature .webpage__feature-container__feature-text {
  max-width: 700px;
}
.webpage__whatweb-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 4rem 0 2rem;
}
.webpage__whatweb-heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  max-width: 510px;
  font-family: var(--font-family);
}
.webpage__whatweb-heading p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  font-family: var(--font-family);
  color: var(--color-subtext);
  cursor: pointer;
}
.webpage__whatweb-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 2rem;
}
.webpage__whatweb-container .webpage__features-container__feature {
  flex: 1;
  margin: 1rem;
  min-width: 210px;
  display: flex;
  flex-direction: column;
}
.webpage__whatweb-container .webpage__features-container_feature-text{
  margin-top: 0.5rem;
}
@media screen and (max-width: 850px) {
  .webpage__whatweb-heading {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0rem;
  }

  .web__whatweb-heading p {
      margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  /* Customizing feature component as per needs */
  .webpage__whatweb-feature .webpage__features-container__feature {
      flex-direction: column;
  }

  /* Customizing feature component as per needs */
  .webpage__whatweb-feature .webpage__features-container__feature-text {
      margin-top: 0.5rem;
  }

  .webpage__whatweb-heading h1 {
      font-size: 28px;
      line-height: 40px;
  }
}

@media screen and (max-width: 450px) {
  /* Customizing feature component as per needs */
  .webpage__whatweb-container .webpage__features-container__feature {
      margin: 1rem 0;
      min-width: 100%;
  }
  .webpage__whatweb{
    padding: 2rem;
  }
}
