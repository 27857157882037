.webpage__blog{
   display: flex;
   flex-direction: column; 
}
.webpage__blog-heading{
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
}
.webpage__blog-heading h1{
    font-family: var(--font-family);
    font-size: 62px;
    font-weight: 800;
    line-height: 75px;
    letter-spacing: -0.04em;

}
.webpage__blog-container{
    display: flex;
    flex-direction: row;

}
.webpage__blog-container_groupA{
    flex: 0.75;
    margin-right: 2rem;
}
.webpage__blog-container_groupB{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}
@media screen and (max-width: 990px){
    .webpage__blog-container{

        flex-direction: column-reverse;
    }
    .webpage__blog-container_groupA{
        margin: 2rem 0;
    }
    .webpage__blog-container_groupA .webpage__blog-container_article{
        width: 48%;
    }
    .webpage__blog-container_groupA .webpage__blog-container_article-image{
        height: 250px;
    }
}
@media screen and (max-width: 700px){
    .webpage__blog-container_groupB{
        grid-template-columns: repeat(1, 1fr);
    }
    .webpage__blog-container_groupA .webpage__blog-container_article {
        width: 100%;
    }
    .webpage__blog-heading h1{
        font-size: 46px;
        line-height: 52px;
    }
}
@media screen and (max-width:550px) {
    .webpage__blog-heading h1{
        font-size: 34px;
        line-height: 42px;
    }
    
}